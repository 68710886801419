<template>
  <div class="container">
    <van-nav-bar
      :title="newsDetail.title"
      left-arrow
      fixed
      placeholder
      @click-left="onClickLeft"
    />
    <div class="content" v-html="newsDetail.newsContent"></div>
  </div>
</template>

<script>
import baseURL from "../../utils/config";
export default {
  name: "NewsDetail",
  data() {
    return {
      newsDetail: "",
      imgUrl: baseURL.imageUrl,
    };
  },
  mounted() {
    this.getNewsDetail();
  },
  methods: {
    getNewsDetail() {
      this.$api.getNewsDetail(this.$route.params.newsID).then((res) => {
        this.newsDetail = res;
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.container {
  width: 100%;
}
.van-nav-bar .van-icon {
  color: black;
}
.van-nav-bar__title {
  font-size: 18px;
  font-weight: bold;
}
.topImg {
  width: 100%;
}
.content {
  margin: 1rem;
}
</style>